type PluginManifest = {
  id: string;
  events: string[];
  beforeEvent: Function;
  beforeDatasetUpdate: Function;
};

type LegendItem = {
  datasetIndex: number;
  fillStyle: string;
  strokeStyle: string;
  text: string;
  [key: string]: any;
};

type Dataset = {
  data: number[];
  label: string;
  backgroundColor: string;
  [key: string]: any;
};

type BeforeEventArgs = {
  type: string;
  native: PointerEvent;
  [key: string]: any;
};

/**
 * This plugin changes default behavior of clicks for legend items.
 * It switches off all item and displays only selected one.
 * It allows to select multiple items when pressing "shift".
 * You can also display legend in form of table with min, max, avg and current
 * values by setting:
 * chart.options.plugins.PrometheusChartLegendPlugin.displayTable = true
 */
const Legend = (): PluginManifest => {
  let chartObject: any;
  let initialized: boolean = false;
  let legendContainer: HTMLElement;
  let selectedDatasets: number[] = [];

  const createTableRow = (legendItem: LegendItem, dataset: Dataset) => {
    const { data } = dataset;
    const min = Math.min(...data);
    const max = Math.max(...data);
    const avg =
      data.reduce((sum, value) => sum + Number(value), 0) / data.length;
    const current = Number(data[data.length - 1]);

    const columns = [
      `<div class="legend-color" style="background-color: ${legendItem.fillStyle}"></div>${legendItem.text}`,
      chartObject.options.scales.yAxes[0].ticks.callback(min),
      chartObject.options.scales.yAxes[0].ticks.callback(max),
      chartObject.options.scales.yAxes[0].ticks.callback(avg),
      chartObject.options.scales.yAxes[0].ticks.callback(current),
    ];

    let visible;
    if (
      selectedDatasets.length === 0 ||
      selectedDatasets.includes(legendItem.datasetIndex)
    ) {
      visible = `<tr class="visible">`;
    } else {
      visible = `<tr>`;
    }
    return (
      columns.reduce((row, value) => row + `<td>${value}</td>`, visible) +
      "</tr>"
    );
  };

  const drawTable = (chart: any) => {
    const legendItems: LegendItem[] = chart.legend.legendItems;
    const datasets: Dataset[] = chart.data.datasets;
    const rows = legendItems.reduce(
      (table: string, item) =>
        table + String(createTableRow(item, datasets[item.datasetIndex])),
      "",
    );
    const headers = ["", "min", "max", "avg", "current"];
    const headerRow =
      headers.reduce(
        (row, name: string) => row + `<td>${name}</td>`,
        `<tr class="visible">`,
      ) + "</tr>";
    return `<table>${headerRow + rows}</table>`;
  };

  const initializeLegendTable = (chart: any) => {
    if (!initialized) {
      chart.canvas.parentElement.insertAdjacentHTML(
        "beforeend",
        `<div class="chart-legend"></div>`,
      );
      chart.options.legendCallback = drawTable;
      legendContainer =
        chart.canvas.parentElement.querySelector(".chart-legend");
      legendContainer.addEventListener("click", handleTableLegendClick);
      chartObject = chart;
      initialized = true;
    }
  };

  const updateSelectedDatasets = (index: number, multiple: boolean) => {
    if (selectedDatasets.includes(index)) {
      if (multiple) {
        selectedDatasets = selectedDatasets.filter(
          (value: number) => value !== index,
        );
      } else {
        selectedDatasets = [];
      }
    } else {
      if (multiple) {
        selectedDatasets.push(index);
      } else {
        selectedDatasets = [index];
      }
    }
  };

  const handleTableLegendClick = (e: MouseEvent) => {
    if (!e.target) return;

    let target = e.target as HTMLTableRowElement;
    while (target.tagName.toLowerCase() !== "tr") {
      target = target.parentElement as HTMLTableRowElement;
    }
    updateSelectedDatasets(target.rowIndex - 1, e.shiftKey);
    chartObject.update();
  };

  const beforeEvent = (chart: any, args: BeforeEventArgs) => {
    if (args.type !== "click") return;

    if (chart.options.legend.display) {
      const xPos = args.x;
      const yPos = args.y;

      const selectedIndex = chart.legend.legendHitBoxes.findIndex(
        (box: any) => {
          const { top, left, width, height } = box;
          const [right, bottom] = [left + width, top + height];
          return xPos >= left && xPos <= right && yPos >= top && yPos <= bottom;
        },
      );
      updateSelectedDatasets(selectedIndex, args.native.shiftKey);
    }
  };

  const beforeDatasetUpdate = (chart: any, args: any) => {
    if (chart.options.plugins.PrometheusChartLegendPlugin.displayTable) {
      initializeLegendTable(chart);
      legendContainer.innerHTML = chart.generateLegend();
    }

    if (selectedDatasets.length === 0) {
      args.meta.hidden = null;
      chart.legend.legendItems[args.index].hidden = false;
      return;
    }

    if (selectedDatasets.includes(args.index)) {
      args.meta.hidden = null;
      chart.legend.legendItems[args.index].hidden = false;
    } else {
      args.meta.hidden = true;
      chart.legend.legendItems[args.index].hidden = true;
    }
  };

  return {
    id: "PrometheusChartLegendPlugin",
    events: ["click"],
    beforeEvent,
    beforeDatasetUpdate,
  };
};

export default Legend;
