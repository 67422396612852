import { useEffect, useState, useCallback } from "react";
import FetchAPI from "../../../api/FetchAPI";
import { Grid, Icon, Input, Select, Checkbox, Popup } from "semantic-ui-react";
import {
  convertArrayToSelectOptions,
  toastError,
} from "../../../app_shared_functions";
import { toast } from "react-toastify";
import EInvoiceRequestModal from "./EInvoiceRequestModal";

const InvoiceSettingsSub = () => {
  const [settings, setSettings] = useState(null);
  const [invoiceMethod, setInvoiceMethod] = useState(null);
  const [formChanged, setFormChanged] = useState(false);
  const [canRequestEInvoice, setCanRequestEInvoice] = useState(false);
  const [isUpdating, setIsUpdating] = useState(true);
  const [automaticPayment, setAutomaticPayment] = useState(false);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);

  useEffect(() => {
    FetchAPI.Account.showContact().then((res) => {
      if (
        res.data?.country?.code === "se" &&
        res.data?.companyname &&
        res.data.orgno
      ) {
        setCanRequestEInvoice(true);
      }
    });

    FetchAPI.Account.getSettings()
      .then((res) => {
        setSettings(res.data.settings);
      })
      .catch((err) => {
        toastError(err, "Error loading invoice settings data!");
      });
  }, []);

  useEffect(() => {
    if (settings) {
      setInvoiceMethod(settings.invoice_method);
      setAutomaticPayment(settings.automatic_payment === "t");
      setIsUpdating(false);
    }
    if (settings?.available_invoice_methods.includes("e-invoice")) {
      setCanRequestEInvoice(false);
    }
  }, [settings]);

  const updateFormData = (name, value) => {
    setInvoiceMethod(value);
    if (value !== settings.invoice_method) {
      setFormChanged(true);
    } else {
      setFormChanged(false);
    }
  };
  const automatiPaymentChangeHandler = () => {
    setAutomaticPayment(!automaticPayment);
    const settingsAutomaticPaymentBoolean = settings.automatic_payment === "t";
    if (automaticPayment === settingsAutomaticPaymentBoolean) {
      setFormChanged(true);
    } else {
      setFormChanged(false);
    }
  };

  const handleUpdateInvoice = () => {
    setIsUpdating(true);
    const objectToSend = {
      settings: {
        invoice_method: invoiceMethod,
        automatic_payment: automaticPayment,
      },
    };
    FetchAPI.Account.updateSettings(objectToSend)
      .then((res) => {
        setSettings(res.data.settings);
        toast.success("Invoice settings saved!");
        setFormChanged(false);
      })
      .catch((err) => {
        toastError(err, "Invoice settings failed!");
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const availableInvoiceMethods = convertArrayToSelectOptions(
    settings?.available_invoice_methods,
  );

  const renderUpdateButton = () => {
    if (formChanged) {
      if (isUpdating) {
        return (
          <button className="float-right button button--green overflow-hidden button--icon__right">
            <Icon loading name="spinner" />
            <span>Updating</span>
          </button>
        );
      } else {
        return (
          <button
            className="float-right button button--green"
            onClick={() => handleUpdateInvoice()}
          >
            <span>Update</span>
          </button>
        );
      }
    }

    if (availableInvoiceMethods.length === 1) {
      return (
        <p className="color-gray">
          <Icon
            name="warning circle"
            className="margin-right-half color-gray"
          />
          The only invoice method currently available in your country is{" "}
          {settings?.available_invoice_methods}.
        </p>
      );
    }

    return null;
  };
  const handleInvoiceModalOpen = useCallback(() => {
    setInvoiceModalOpen(true);
  }, []);

  return (
    <Grid className=" margin-right-00  margin-left-10">
      <Grid.Row className="separator padding-top-30">
        <Grid.Column
          textAlign="left"
          width={16}
          className="flex vcenter margin-top-00"
        >
          <h5 className=" padding-top-30 padding-bottom-20">
            Invoice settings
          </h5>
        </Grid.Column>
        <Grid.Column
          textAlign="left"
          width={10}
          className="flex vcenter margin-top-15"
        >
          <h5>Receive invoices by</h5>
        </Grid.Column>
        <Grid.Column
          textAlign="left"
          width={6}
          className="margin-top-15 flex vcenter"
        >
          {
            // display a selector if there are more than one available invoice option
            // otherwise fall back to a simple disabled input box with the only option: email
            availableInvoiceMethods.length > 1 ? (
              <Select
                icon="chevron circle down"
                className="select-box full"
                options={availableInvoiceMethods}
                placeholder="Select a method"
                value={invoiceMethod}
                onChange={(e, d) =>
                  updateFormData(
                    "invoice_method",
                    availableInvoiceMethods.find(
                      (item) => item.value === d.value,
                    ).value,
                  )
                }
              />
            ) : (
              <Input
                disabled
                value={invoiceMethod || availableInvoiceMethods[0]}
              />
            )
          }
        </Grid.Column>
        <Grid.Column
          textAlign="left"
          width={10}
          className="flex vcenter margin-top-15"
        >
          <h5>
            Automatic Payment
            <Popup
              trigger={
                <Icon
                  name="warning circle"
                  color="grey"
                  size="small"
                  className="margin-left-half"
                />
              }
            >
              By enabling this option, your credit card will be automatically
              charged on the due date of each invoice.
            </Popup>
          </h5>
        </Grid.Column>
        <Grid.Column
          textAlign="left"
          width={6}
          className="margin-top-15 flex vcenter"
        >
          <Checkbox
            className="display-inlineblock"
            toggle
            checked={automaticPayment}
            label={automaticPayment ? "Enabled" : "Disabled"}
            onChange={automatiPaymentChangeHandler}
          />
        </Grid.Column>
        <Grid.Column textAlign="left" width={16} className="margin-top-30">
          {renderUpdateButton()}
        </Grid.Column>

        {canRequestEInvoice && (
          <Grid.Column
            textAlign="left"
            width={16}
            className="margin-top-15"
            style={{ fontSize: ".9rem", fontWeight: "300" }}
          >
            If you are interested in e-invoice, please submit a{" "}
            <button
              onClick={handleInvoiceModalOpen}
              className="button button--green"
            >
              Request form
            </button>
          </Grid.Column>
        )}
      </Grid.Row>

      {canRequestEInvoice && (
        <EInvoiceRequestModal
          open={invoiceModalOpen}
          setOpen={setInvoiceModalOpen}
        />
      )}
    </Grid>
  );
};

export default InvoiceSettingsSub;
