import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Worker } from "../types";
import LicensePrice from "./bits/LicensePrice";
import WorkerPrice from "./bits/WorkerPrice";
import { findFlavorID } from "./helpers/findFlavorID";
import useIsLoading from "./hooks/useIsLoading";
import { valuesOf } from "../../shared-functions/objects";
import CostTitle from "./bits/CostTitle";
import FetchAPI from "../../api/FetchAPI";
import { getCurrentProjectID } from "../../app_shared_functions";
import { RootStateOrAny, useSelector } from "react-redux";
import { Flavor } from "../../api/resources/Compute/Flavors";

type CostEstimationProps = {
  region: string;
  regionKey: string;
  workers: Worker[];
  rounded?: boolean;
  excludeLicense?: boolean;
  isReloadingRegion?: boolean;
  setIsReloadingRegion?: ((isReloadingRegion: boolean) => void) | null;
};

export type Prices = {
  license: number;
  workers: { [key: string]: number };
  currency: string;
};

const CostEstimation = ({
  region,
  regionKey,
  workers,
  rounded,
  isReloadingRegion = false,
  setIsReloadingRegion = null,
  excludeLicense = false,
}: CostEstimationProps) => {
  // STATES //
  const [prices, setPrices] = useState<Prices>({
    license: 0,
    workers: {},
    currency: "",
  });
  const [expanded, setExpanded] = useState(false);
  const [flavors, setFlavors] = useState<Flavor[]>([]);

  // CUSTOM HOOKS INVOCATION
  const isLoading = useIsLoading(prices, workers.length);
  const projects = useSelector((state: RootStateOrAny) => state.projects);
  const projectId = getCurrentProjectID(projects, region) || "";

  // CALLBACKS
  const updatePrice = useCallback((key: string, newPrice: number) => {
    setPrices((prevPrices) => {
      const updatedPrices = { ...prevPrices };
      if (key === "license") {
        updatedPrices.license = newPrice;
      } else {
        updatedPrices.workers[key] = newPrice;
      }
      return updatedPrices;
    });
  }, []);

  const updateCurrency = useCallback((currency: string) => {
    setPrices((prevPrices) => {
      const updatedPrices = { ...prevPrices };
      updatedPrices.currency = currency;
      return updatedPrices;
    });
  }, []);

  const updateExpanded = useCallback(
    () => setExpanded((expanded) => !expanded),
    [],
  );

  // MEMOIZED VALUES
  const totalEstimation = useMemo(() => {
    const total = valuesOf(prices.workers)
      .reduce(
        (acc: number, val) => acc + val,
        excludeLicense ? 0 : prices.license,
      )
      .toFixed(2);
    return `${total} ${prices.currency}`;
  }, [prices, excludeLicense]);

  const flavorIds = useMemo(
    () =>
      workers.reduce(
        (flavorIds: { [key: string]: string }, worker) => ({
          ...flavorIds,
          [worker.name]: findFlavorID(flavors, worker.machine.type),
        }),
        {},
      ),
    [flavors, workers],
  );

  // EFFECTS

  useEffect(() => {
    FetchAPI.Compute.Flavors.getList({
      region,
      project_id: projectId,
    }).then((res) => {
      setFlavors(res.data);
      if (setIsReloadingRegion) {
        setIsReloadingRegion(false);
      }
    });
  }, [projectId, projects, region, setIsReloadingRegion]);

  return (
    <div className="invoice_wrapper">
      <div
        className={`estimated-invoice estimated-invoice--${
          rounded ? "rounded" : ""
        }`}
      >
        <CostTitle
          isLoading={isLoading}
          totalEstimation={totalEstimation}
          expanded={expanded}
          updateExpanded={updateExpanded}
        />

        <div
          className={`estimated-invoice__body estimated-invoice__${
            expanded ? "expanded" : "shrunk"
          }`}
        >
          <LicensePrice
            excludeLicense={excludeLicense}
            regionKey={regionKey}
            updatePrice={updatePrice}
            updateCurrency={updateCurrency}
          />
          {setIsReloadingRegion !== null &&
            !isReloadingRegion &&
            workers.map((worker, key) => (
              <WorkerPrice
                worker={worker}
                region={region}
                key={key}
                index={key}
                flavorId={flavorIds[worker.name]}
                updatePrice={updatePrice}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default CostEstimation;
