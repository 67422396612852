import { Dropdown, DropdownItemProps } from "semantic-ui-react";
import React, { SyntheticEvent } from "react";

export type Options = {
  [workerGroupName: string]: string[];
};

type NodeSelectProps = {
  selected: string;
  options: Options;
  onChange: (event: SyntheticEvent, data: DropdownItemProps) => void;
};

const NodeSelect = ({ selected, options, onChange }: NodeSelectProps) => {
  return (
    <Dropdown
      className={
        "full-width-select no-scrolling-text ui selection dropdown mark-selected node-select select-box full"
      }
      text={selected}
      disabled={selected === undefined}
    >
      <Dropdown.Menu>
        {Object.keys(options).map((workerName: string) => (
          <React.Fragment key={workerName}>
            <div className={"group"}>Worker: {workerName}</div>
            {options[workerName].map((nodeName: string) => (
              <Dropdown.Item
                key={nodeName}
                onClick={onChange}
                value={nodeName}
                className={nodeName === selected ? "selected" : ""}
              >
                {nodeName}
              </Dropdown.Item>
            ))}
          </React.Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NodeSelect;
