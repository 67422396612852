import { TShoot } from "../types";
import MonitoringHeader from "./monitoring-bits/MonitoringHeader";
import { useSelector } from "react-redux";
import { getGardenerDomain } from "../reducer/selectors";
import WorkerGroupsDetails from "./monitoring-bits/WorkerGroupsDetails";
import { Icon, Tab } from "semantic-ui-react";
import FetchAPI from "../../api/FetchAPI";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  NodeDetails as NodeDetailsType,
  NodeOverview,
  WorkerGroupDetails,
} from "./Monitoring.types";
import { toastError } from "../../app_shared_functions";
import NodesOverview, { SortableField } from "./monitoring-bits/NodesOverview";
import NodeDetails from "./monitoring-bits/NodeDetails";
import { Options as NodeNames } from "./monitoring-bits/NodeSelect";

type MonitoringProps = {
  shoot: TShoot;
};

const dashboards = ["Worker Group Details", "Node Details", "Nodes Overview"];

export type Dashboard = (typeof dashboards)[number];

const Monitoring = ({ shoot }: MonitoringProps) => {
  const gardenerRegionTag = useSelector(getGardenerDomain);
  const openStackRegionTag = shoot.spec.region;
  const shootName = shoot.metadata.name;
  const projectId = shoot.project_id;

  const workerNames = useMemo(
    () => shoot.spec.provider.workers.map((worker) => worker.name),
    [shoot.spec.provider.workers],
  );

  const [workerGroupsDetails, setWorkerGroupsDetails] =
    useState<WorkerGroupDetails[]>();
  const [nodesOverview, setNodesOverview] = useState<NodeOverview[]>();
  const [nodeDetails, setNodeDetails] = useState<NodeDetailsType>();
  const [nodeNames, setNodeNames] = useState<NodeNames>({});
  const [sortField, setSortField] = useState<SortableField>("workerGroup");
  const [sortAscending, setSortAscending] = useState<boolean>(true);

  const [selectedDashboard, setSelectedDashboard] = useState<Dashboard>(
    dashboards[0],
  );
  const changeSelectedDashboard = useCallback((_, d) => {
    setWorkerGroupsDetails(undefined);
    setNodeDetails(undefined);
    setNodesOverview(undefined);
    setSelectedDashboard(d.value);
  }, []);

  const [selectedNode, setSelectedNode] = useState<string>("");
  const changeSelectedNode = useCallback(
    (_, d) => setSelectedNode(d.value),
    [],
  );

  const navigateToNode = useCallback((nodeName: string) => {
    setSelectedDashboard(dashboards[1]);
    setSelectedNode(nodeName);
  }, []);

  const isWorkersDataAvailable = useCallback(() => {
    if (workerGroupsDetails && workerGroupsDetails.length > 0) {
      const populatedWorkers = workerGroupsDetails.filter(
        (worker) => worker.cpuUsage.length > 0,
      );
      if (populatedWorkers.length > 0) {
        return true;
      }
    }
    return false;
  }, [workerGroupsDetails]);

  const isNodeDataAvailable = useCallback(() => {
    return !!(nodeDetails && nodeDetails.cpuUsage.used.length > 0);
  }, [nodeDetails]);

  // Returns next time when data will be available, ex 16:15
  const findNextCollectionTime = useCallback(() => {
    const deliveryTime = new Date();
    deliveryTime.setSeconds(0);
    deliveryTime.setMinutes(Math.ceil(deliveryTime.getMinutes() / 15) * 15);
    return deliveryTime.toLocaleTimeString([], { timeStyle: "short" });
  }, []);

  const fetchWorkerGroupsDetails = useCallback(() => {
    const promises = workerNames.map((workerName) => {
      return FetchAPI.Gardener.Shoots.Monitoring.getShootWorkerGroupDetails({
        gardenerRegionTag,
        openStackRegionTag,
        projectId,
        workerName,
        shootName,
      });
    });
    Promise.all(promises)
      .then((responses) => {
        setWorkerGroupsDetails(
          responses.map((response) => response.data.workerGroupDetails),
        );
      })
      .catch((error) => {
        toastError(error.message);
      });
  }, [
    gardenerRegionTag,
    openStackRegionTag,
    projectId,
    shootName,
    workerNames,
  ]);

  const fetchNodeDetails = useCallback(() => {
    setNodeDetails(undefined);
    FetchAPI.Gardener.Shoots.Monitoring.getShootNodeDetails({
      gardenerRegionTag,
      openStackRegionTag,
      projectId,
      shootName,
      nodeName: selectedNode,
    })
      .then((r) => {
        setNodeDetails(r.data.nodeDetails);
      })
      .catch((error) => {
        toastError(error.message);
      });
  }, [
    gardenerRegionTag,
    openStackRegionTag,
    projectId,
    selectedNode,
    shootName,
  ]);

  const fetchNodesOverview = useCallback(() => {
    const promises = workerNames.map((workerName) => {
      return FetchAPI.Gardener.Shoots.Monitoring.getShootWorkerGroupNodesOverview(
        {
          gardenerRegionTag,
          openStackRegionTag,
          projectId,
          workerName,
          shootName,
        },
      );
    });
    Promise.all(promises)
      .then((responses) => {
        const nodes: NodeOverview[] = [];
        responses.forEach((response) =>
          response.data.nodes.forEach((node) => nodes.push(node)),
        );
        setNodesOverview(nodes);
      })
      .catch((error) => {
        toastError(error.message);
      });
  }, [
    gardenerRegionTag,
    openStackRegionTag,
    projectId,
    shootName,
    workerNames,
  ]);

  const fetchNodeNames = useCallback(() => {
    const promises = workerNames.map((workerName) => {
      return FetchAPI.Gardener.Shoots.Monitoring.getShootNodeNames({
        gardenerRegionTag,
        openStackRegionTag,
        projectId,
        shootName,
        workerName,
      });
    });
    Promise.all(promises).then((responses) => {
      let names: NodeNames = {};

      // Group node names by worker name
      responses.forEach((response, index) => {
        names[workerNames[index]] = response.data.nodeNames;
      });

      setNodeNames(names);
      setSelectedNode(names[workerNames[0]][0]);
    });
  }, [
    gardenerRegionTag,
    openStackRegionTag,
    projectId,
    shootName,
    workerNames,
  ]);

  const handleDashboardChange = useCallback(() => {
    switch (selectedDashboard) {
      case dashboards[0]:
        fetchWorkerGroupsDetails();
        break;
      case dashboards[1]:
        fetchNodeDetails();
        break;
      case dashboards[2]:
        fetchNodesOverview();
        break;
    }
  }, [
    fetchNodeDetails,
    fetchNodesOverview,
    fetchWorkerGroupsDetails,
    selectedDashboard,
  ]);

  useEffect(handleDashboardChange, [selectedDashboard, handleDashboardChange]);
  useEffect(fetchNodeNames, [fetchNodeNames]);

  const LoadingIcon = () => (
    <div className={"flex justify-content-center margin-top-30"}>
      <Icon name={"spinner"} loading size={"large"} />
    </div>
  );

  return (
    <Tab.Pane className={"monitoring-tab"}>
      <MonitoringHeader
        openStackRegionTag={openStackRegionTag}
        gardenerRegionTag={gardenerRegionTag}
        shootName={shootName}
        projectId={projectId}
        dashboards={dashboards}
        selectedDashboard={selectedDashboard}
        changeSelectedDashboard={changeSelectedDashboard}
        nodeNames={nodeNames}
        selectedNode={selectedNode}
        changeSelectedNode={changeSelectedNode}
        displayNodeSelect={isNodeDataAvailable()}
      />

      {selectedDashboard === dashboards[0] &&
        (workerGroupsDetails ? (
          isWorkersDataAvailable() ? (
            <WorkerGroupsDetails workerGroupsDetails={workerGroupsDetails} />
          ) : (
            <div
              className={
                "flex justify-content-center color-gray-mid-light font-M margin-top-50 margin-bottom-50"
              }
            >
              Worker data is being collected and will be available at{" "}
              {findNextCollectionTime()}.
            </div>
          )
        ) : (
          <LoadingIcon />
        ))}

      {selectedDashboard === dashboards[1] &&
        (nodeDetails ? (
          isNodeDataAvailable() ? (
            <NodeDetails nodeDetails={nodeDetails} />
          ) : (
            <div
              className={
                "flex justify-content-center color-gray-mid-light font-M margin-top-50 margin-bottom-50"
              }
            >
              Node data is being collected and will be available at{" "}
              {findNextCollectionTime()}.
            </div>
          )
        ) : (
          <LoadingIcon />
        ))}

      {selectedDashboard === dashboards[2] &&
        (nodesOverview ? (
          <NodesOverview
            nodes={nodesOverview}
            navigateToNodeCallback={navigateToNode}
            sortField={sortField}
            setSortField={setSortField}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
          />
        ) : (
          <LoadingIcon />
        ))}
    </Tab.Pane>
  );
};

export default Monitoring;
