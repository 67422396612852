import { Line as LineChart } from "react-chartjs-2";
import { Sample } from "../../Monitoring.types";
import VerticalLine from "./Plugin/VerticalLine";
import Legend from "./Plugin/Legend";
import Tooltip from "./Plugin/Tooltip";
import Scale from "./Plugin/Scale";
import { Dataset } from "./Prometheus.types";

type PrometheusLineChartProps = {
  title: string;
  labels: string[];
  tooltipLabels: string[];
  verticalLabel: string;
  verticalUnit: string;
  verticalPrecision: number;
  tooltipUnit: string;
  tooltipPrecision: number;
  datasets: Dataset[];
  displayLegendTable?: boolean;
};

const Line = ({
  title,
  labels,
  tooltipLabels,
  verticalLabel,
  verticalUnit,
  verticalPrecision,
  tooltipUnit,
  tooltipPrecision,
  datasets,
  displayLegendTable = false,
}: PrometheusLineChartProps) => {
  const processedData = {
    labels: labels,
    datasets: datasets.map((dataset: Dataset) => {
      return {
        ...dataset,
        borderColor: dataset.borderColor,
        backgroundColor: dataset.borderColor,
        borderWidth: 1,
        data: dataset.data.map((sample: Sample) => sample.value),
        fill: false,
        pointRadius: 0,
      };
    }),
  };

  const options = {
    plugins: {
      PrometheusChartLegendPlugin: {
        displayTable: displayLegendTable,
      },
      TooltipPlugin: {
        unit: tooltipUnit,
        windowMargin: {
          right: 45,
          bottom: 0,
        },
      },
    },
    layout: {
      padding: {
        top: 0,
        right: 30,
        left: 30,
        bottom: 30,
      },
    },
    legend: {
      display: !displayLegendTable,
      position: "bottom" as const,
      align: "start" as const,
    },
    title: {
      display: title.length > 0,
      text: title,
      fontSize: 14,
      padding: 15,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            callback: (_value: number, index: number) => {
              return index % 8 === 0 && index !== 0 ? labels[index] : "";
            },
          },
          gridLines: {
            drawTicks: false,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: verticalLabel.length > 0,
            labelString: verticalLabel,
          },
          ticks: {
            beginAtZero: true,
            callback: (value: number) => {
              return value.toFixed(verticalPrecision) + verticalUnit;
            },
          },
        },
      ],
    },
    tooltips: {
      intersect: false,
      mode: "index" as const,
      callbacks: {
        title: (items: any) => {
          return tooltipLabels[items[0].index];
        },
        label: (item: any) => {
          return Number(item.value).toFixed(tooltipPrecision) + tooltipUnit;
        },
      },
    },
  };

  const plugins: any[] = [VerticalLine(), Scale(), Tooltip(), Legend()];

  return (
    <div>
      <LineChart data={processedData} options={options} plugins={plugins} />
    </div>
  );
};

export default Line;
