export type PluginManifest = {
  id: string;
  events: string[];
  afterEvent: Function;
  afterLayout: Function;
};

/**
 * This plugin displays custom tooltip on Line Charts.
 */
const Tooltip = (): PluginManifest => {
  let chartObject: any;
  let chartContainer: HTMLElement;
  let tooltipElement: HTMLElement | null;
  let initialized: boolean = false;

  // Makes sure that tooltip is always displayed next to mouse cursor
  const updateTooltipPosition = (_chart: any, args: any): void => {
    if (!tooltipElement) {
      return;
    }

    const mouseX = args.x;
    const mouseY = args.y;

    // Stop displaying when cursor is out of chart area
    const { left, right, top, bottom } = chartObject.chartArea;
    if (mouseX > right || mouseX < left || mouseY > bottom || mouseY < top) {
      chartContainer.style.cursor = "default";
      tooltipElement.style.opacity = "0";
      tooltipElement.style.top = "0";
      tooltipElement.style.left = "0";
      return;
    }

    let tooltipPositionLeft = mouseX + 10;
    let tooltipPositionTop = mouseY + 10;

    const tooltipWidth = tooltipElement.offsetWidth;
    const tooltipHeight = tooltipElement.offsetHeight;

    const { innerHeight, innerWidth } = window;

    const nativeEventX = args.native.x;
    const nativeEventY = args.native.y;

    // Switch tooltip position left/right, top/bottom when tooltip gets
    // close to the browser window border
    const windowMargin = chartObject.options.plugins.TooltipPlugin.windowMargin;

    if (nativeEventX + tooltipWidth + windowMargin.right + 10 > innerWidth) {
      tooltipPositionLeft = mouseX - tooltipWidth - 10;
    }

    if (nativeEventY + tooltipHeight + windowMargin.bottom + 10 > innerHeight) {
      tooltipPositionTop = mouseY - tooltipHeight - 10;
    }

    chartContainer.style.cursor = "crosshair";
    tooltipElement.style.opacity = "1";
    tooltipElement.style.left = tooltipPositionLeft + "px";
    tooltipElement.style.top = tooltipPositionTop + "px";
  };

  // Create table with values inside tooltip
  const generateTable = (model: any) => {
    const datasets = chartObject.data.datasets;
    const rows = model.dataPoints.map((point: any) => {
      const { datasetIndex, value } = point;
      const { label, backgroundColor } = datasets[datasetIndex];
      const columns = [
        `<div class="legend" style="background-color: ${backgroundColor}"></div> ${label}: `,
        chartObject.options.tooltips.callbacks.label({ value }),
      ];
      return (
        columns.reduce(
          (row, column: string) => row + `<td>${column}</td>`,
          "<tr>",
        ) + "</tr>"
      );
    });
    return (
      `<b>${model.title[0]}</b>` +
      rows.reduce(
        (table: string, content: string) => table + content,
        "<table>",
      ) +
      "</table>"
    );
  };

  // Callback added in chart options
  const generateTooltip = (model: any) => {
    chartContainer = chartObject.canvas.parentNode;
    tooltipElement = chartContainer.querySelector(".chart-tooltip");

    if (!tooltipElement) {
      tooltipElement = document.createElement("div");
      tooltipElement.classList.add("chart-tooltip");
      chartContainer.appendChild(tooltipElement);
    }

    if (model.opacity === 0) {
      tooltipElement.style.opacity = "0";
      return;
    }

    chartContainer.style.position = "relative";
    tooltipElement.style.padding =
      model.yPadding + "px " + model.xPadding + "px";
    tooltipElement.style.fontFamily = model._bodyFontFamily;
    tooltipElement.style.fontSize = model.bodyFontSize + "px";
    tooltipElement.style.fontStyle = model._bodyFontStyle;
    tooltipElement.style.color = model.bodyFontColor;

    tooltipElement.innerHTML = generateTable(model);
  };

  const afterLayout = (chart: any) => {
    if (!initialized) {
      chart.options.tooltips.enabled = false;
      chart.options.tooltips.custom = generateTooltip;
      chartObject = chart;
      initialized = true;
    }
  };

  return {
    id: "TooltipPlugin",
    events: ["mousemove"],
    afterLayout,
    afterEvent: updateTooltipPosition,
  };
};

export default Tooltip;
