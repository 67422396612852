import { Sample } from "../../Monitoring.types";

export type Dataset = {
  label: string;
  borderColor?: string;
  data: Sample[];
};

export enum Colors {
  green = "rgba(115, 191, 105, 1)",
  yellow = "rgba(250, 222, 42, 1)",
  red = "rgba(242, 73, 92, 1)",
  blue = "rgba(87, 148, 242, 1)",
  orange = "rgba(255, 152, 48, 1)",
  purple = "rgba(184, 119, 217, 1)",
  grey = "rgba(184, 119, 217, 1)",
}

export type ChartColors = keyof typeof Colors;
