import { Doughnut } from "react-chartjs-2";
import { Colors } from "./Prometheus.types";

type UtilizationProps = {
  value: number;
  title: string;
};

const Utilization = ({ value, title }: UtilizationProps) => {
  const options = {
    circumference: (5 * Math.PI) / 4,
    rotation: -((5 / 4 - 1) / 2 + 1) * Math.PI,
    events: [],
    title: {
      display: title.length > 0,
      text: title,
      fontSize: 14,
      padding: 15,
    },
    tooltips: {
      enabled: false,
    },
  };

  if (value > 0 && value <= 1) {
    value *= 100;
  }

  const remaining = 100 - value;

  const data = {
    datasets: [
      {
        data: [95, 4, 1],
        backgroundColor: [Colors.green, Colors.yellow, Colors.red],
        weight: 1,
      },
      {
        data: [value, remaining],
        backgroundColor: [Colors.green],
        weight: 5,
      },
    ],
  };

  const widthAdjustment = options.title.display
    ? "min-width-250 width50p"
    : "min-width-250 width40p margin-top-30";
  const textAdjustment = options.title.display
    ? "top__75p font-double-M"
    : "top__75p font-double-L";

  return (
    <div className={"flex justify-content-center position-relative"}>
      <div className={widthAdjustment}>
        <Doughnut data={data} options={options} />
      </div>
      <div className={`position-absolute ${textAdjustment}`}>
        {Number(value).toFixed(2)}
      </div>
    </div>
  );
};

export default Utilization;
