type Line = {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
};

export type PluginManifest = {
  id: string;
  events: string[];
  beforeDatasetsDraw: Function;
  afterEvent: Function;
};

/**
 * This plugin adds vertical line to the cursor in Line Chart.
 */

const VerticalLine = (): PluginManifest => {
  let line: Line | undefined;

  const beforeDatasetsDraw = (chart: any) => {
    if (line) {
      const { ctx } = chart;
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = "rgba(0, 0, 0, 0.5)";
      ctx.moveTo(line.startX, line.startY);
      ctx.lineTo(line.endX, line.endY);
      ctx.stroke();
      ctx.restore();
    }
  };

  const afterEvent = (chart: any, args: any) => {
    const {
      chartArea: { left, right, top, bottom },
    } = chart;
    const xPos = args.x;
    const yPos = args.y;

    if (xPos >= left && xPos <= right && yPos >= top && yPos <= bottom) {
      line = {
        startX: xPos,
        startY: top,
        endX: xPos,
        endY: bottom,
      };
    } else if (line) {
      line = undefined;
    }
  };

  return {
    id: "VerticalLinePlugin",
    events: ["mousemove"],
    beforeDatasetsDraw,
    afterEvent,
  };
};

export default VerticalLine;
