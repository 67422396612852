import config from "../../../config";
import getFetch from "../../getFetch";
import Monitoring from "./Monitoring";

type ShootRequest = {
  region?: string;
  project_id?: string;
  gardenDomain?: string;
  shoot?: any;
  objectToSend?: any;
  metadata?: any;
  name?: string;
  expirationSeconds?: number;
  params?: any;
};

const Shoots = {
  Monitoring,
  create: function ({
    region,
    project_id,
    gardenDomain,
    objectToSend,
  }: ShootRequest) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${region}/${project_id}`,
      type: "post",
      params: objectToSend,
    });
  },

  delete: function ({
    region,
    project_id,
    gardenDomain,
    metadata: { name },
  }: ShootRequest) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${region}/${project_id}/${name}`,
      type: "delete",
    });
  },

  getKubeConfig: function ({ gardenDomain, shoot }: ShootRequest) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${shoot.region}/${shoot.project_id}/${shoot.metadata.name}/kubeconfig`,
      type: "get",
    });
  },

  createAdminKubeConfig: function ({
    gardenDomain,
    shoot,
    expirationSeconds,
  }: ShootRequest) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${shoot.region}/${shoot.project_id}/${shoot.metadata.name}/adminkubeconfig`,
      type: "post",
      params: { config: { expirationSeconds } },
    });
  },

  hibernate: function ({
    region,
    project_id,
    gardenDomain,
    metadata: { name },
  }: ShootRequest) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${region}/${project_id}/${name}/hibernate`,
      type: "post",
    });
  },

  maintain: function ({
    region,
    project_id,
    gardenDomain,
    metadata: { name },
  }: ShootRequest) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${region}/${project_id}/${name}/maintenance`,
      type: "post",
    });
  },

  prepare: function (
    { region, project_id, gardenDomain, metadata: { name } }: ShootRequest,
    upgradeTo: string,
  ) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${region}/${project_id}/${name}/upgrade/prepare?target_version=${upgradeTo}`,
      type: "put",
    });
  },

  reconcile: function ({
    region,
    project_id,
    gardenDomain,
    metadata: { name },
  }: ShootRequest) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${region}/${project_id}/${name}/reconcile`,
      type: "post",
    });
  },

  retry: function ({
    region,
    project_id,
    gardenDomain,
    metadata: { name },
  }: ShootRequest) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${region}/${project_id}/${name}/retry`,
      type: "post",
    });
  },

  revert: function (
    { region, project_id, gardenDomain, metadata: { name } }: ShootRequest,
    upgradeTo: string,
  ) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${region}/${project_id}/${name}/upgrade/revert?target_version=${upgradeTo}`,
      type: "put",
    });
  },

  rotateKubeconfig: function ({
    region,
    project_id,
    gardenDomain,
    metadata: { name },
  }: ShootRequest) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${region}/${project_id}/${name}/kubeconfig/rotate`,
      type: "post",
    });
  },

  rotateCaBundle: function (
    { region, project_id, gardenDomain, metadata: { name } }: ShootRequest,
    stage: string,
  ) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${region}/${project_id}/${name}/ca/rotate`,
      type: "post",
      params: { stage: stage },
    });
  },

  getCaRotationStatus: function ({
    region,
    project_id,
    gardenDomain,
    name,
  }: ShootRequest) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${region}/${project_id}/${name}/ca/rotate`,
      type: "get",
    });
  },

  update: function (
    { region, project_id, gardenDomain, metadata: { name } }: ShootRequest,
    objectToSend: any,
  ) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${region}/${project_id}/${name}`,
      type: "put",
      params: objectToSend,
    });
  },

  validate: function (
    { region, project_id, gardenDomain, metadata: { name } }: ShootRequest,
    upgradeTo: string,
  ) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${region}/${project_id}/${name}/upgrade/check?target_version=${upgradeTo}`,
      type: "get",
    });
  },

  wakeup: function ({
    region,
    project_id,
    gardenDomain,
    metadata: { name },
  }: ShootRequest) {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${gardenDomain}/shoot/${region}/${project_id}/${name}/wakeup`,
      type: "post",
    });
  },
};

export default Shoots;
