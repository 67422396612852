import { NodeDetails as NodeDetailsType } from "../Monitoring.types";
import { Grid } from "semantic-ui-react";
import Line from "./PrometheusChart/Line";
import React from "react";
import { Colors } from "./PrometheusChart/Prometheus.types";
import { keysOf } from "../../../shared-functions/objects";
import Utilization from "./PrometheusChart/Utilization";
import { NoStringsOrNumbers } from "../../types";

type NodeDetailsProps = {
  nodeDetails: NodeDetailsType;
};

type DatasetObjectTypes = Pick<
  NodeDetailsType,
  NoStringsOrNumbers<NodeDetailsType>
>;

const NodeDetails = ({ nodeDetails }: NodeDetailsProps) => {
  const labels = nodeDetails.cpuUsage.used.map((sample) =>
    new Date(sample.time.date).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    }),
  );

  const tooltipLabels = nodeDetails.cpuUsage.used.map((sample) => {
    const date = new Date(sample.time.date);
    return date.toLocaleDateString([], { hour: "2-digit", minute: "2-digit" });
  });

  const createLineDatasets = (name: keyof DatasetObjectTypes) => {
    const keys = Object.keys(nodeDetails[name]);
    const values = nodeDetails[name];
    return keys.map((key, index) => {
      return {
        label: key,
        borderColor: Colors[keysOf(Colors)[index]],
        data: values[key as keyof typeof values],
      };
    });
  };

  const { cpuUtilization, memoryUtilization } = nodeDetails;

  const cpuUsageDatasets = createLineDatasets("cpuUsage");
  const memoryUsageDatasets = createLineDatasets("memoryUsage");
  const filesystemSize = createLineDatasets("filesystemSize");

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column
          mobile={16}
          largeScreen={8}
          widescreen={8}
          className={"margin-bottom-50"}
        >
          <Utilization title={"CPU Utilization"} value={cpuUtilization} />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          largeScreen={8}
          widescreen={8}
          className={"margin-bottom-50"}
        >
          <Utilization title={"Memory Utilization"} value={memoryUtilization} />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          largeScreen={8}
          widescreen={8}
          className={"margin-bottom-40"}
        >
          <Line
            title={`Node CPU Usage`}
            labels={labels}
            tooltipLabels={tooltipLabels}
            verticalLabel={"Cores"}
            verticalUnit={""}
            verticalPrecision={2}
            tooltipUnit={" core"}
            tooltipPrecision={2}
            datasets={cpuUsageDatasets}
            displayLegendTable={true}
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          largeScreen={8}
          widescreen={8}
          className={"margin-bottom-40"}
        >
          <Line
            title={`Node Memory Usage`}
            labels={labels}
            tooltipLabels={tooltipLabels}
            verticalLabel={"Memory"}
            verticalUnit={" GiB"}
            verticalPrecision={2}
            tooltipUnit={" GiB"}
            tooltipPrecision={2}
            datasets={memoryUsageDatasets}
            displayLegendTable={true}
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          largeScreen={8}
          widescreen={8}
          className={"margin-bottom-40"}
        >
          <Line
            title={`Node Filesystem Size`}
            labels={labels}
            tooltipLabels={tooltipLabels}
            verticalLabel={"Filesystem"}
            verticalUnit={" GiB"}
            verticalPrecision={1}
            tooltipUnit={" GiB"}
            tooltipPrecision={1}
            datasets={filesystemSize}
            displayLegendTable={true}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default NodeDetails;
