import { NodeOverview } from "../Monitoring.types";
import { Grid } from "semantic-ui-react";
import { useCallback, useEffect, useMemo, useState } from "react";

type NodesOverviewProps = {
  nodes: NodeOverview[];
  navigateToNodeCallback: (nodeName: string) => void;
  sortField: SortableField;
  setSortField: (field: SortableField) => void;
  sortAscending: boolean;
  setSortAscending: (ascending: boolean) => void;
};

export type SortableField = keyof NodeOverview;

const sortableFields: SortableField[] = [
  "workerGroup",
  "nodeName",
  "cpuUsage",
  "allocatableCpu",
  "idleCpu",
  "memoryUsage",
  "allocatableMemory",
  "unusedMemory",
  "pods",
];

const NodesOverview = ({
  nodes,
  navigateToNodeCallback,
  sortField = sortableFields[0],
  setSortField,
  sortAscending,
  setSortAscending,
}: NodesOverviewProps) => {
  const sortNodes = useCallback(
    (nodes: NodeOverview[], field: SortableField, ascending: boolean) => {
      return [...nodes].sort((a, b) => {
        const value = a[field] > b[field] ? 1 : -1;
        return ascending ? value : -value;
      });
    },
    [],
  );

  const initialNodes = sortNodes(nodes, sortField, sortAscending);
  const [sortedNodes, setSortedNodes] = useState<NodeOverview[]>(initialNodes);

  const adjustByteSize = useCallback((bytes: number) => {
    if (bytes / Math.pow(1024, 2) >= 1000) {
      return (bytes / Math.pow(1024, 3)).toFixed(2) + " GiB";
    }
    return (bytes / Math.pow(1024, 2)).toFixed(2) + " MiB";
  }, []);

  const handleNodeClickCallbacks = useMemo(
    () =>
      nodes.map(
        (node: NodeOverview) => () => navigateToNodeCallback(node.nodeName),
      ),
    [navigateToNodeCallback, nodes],
  );

  const handleSortCallbacks = useMemo(
    () =>
      sortableFields.map((field) => () => {
        const ascending = sortField === field ? !sortAscending : false;
        const sorted = sortNodes(sortedNodes, field, ascending);
        setSortedNodes(sorted);
        setSortField(field);
        setSortAscending(ascending);
      }),
    [
      setSortAscending,
      setSortField,
      sortAscending,
      sortField,
      sortNodes,
      sortedNodes,
    ],
  );

  const getSortClassNames = useCallback(
    (fieldIndex: number) => {
      let classNames = "";
      if (sortField === sortableFields[fieldIndex]) {
        classNames = "sort";
        classNames += sortAscending ? " asc" : " desc";
      }
      return classNames;
    },
    [sortAscending, sortField],
  );

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <table className={"simple-table striped-table nodes-overview-table"}>
            <tbody>
              <tr>
                <td
                  onClick={handleSortCallbacks[0]}
                  className={getSortClassNames(0)}
                >
                  Worker Group
                </td>
                <td
                  onClick={handleSortCallbacks[1]}
                  className={getSortClassNames(1)}
                >
                  Node
                </td>
                <td
                  onClick={handleSortCallbacks[2]}
                  className={getSortClassNames(2)}
                >
                  CPU Usage
                </td>
                <td
                  onClick={handleSortCallbacks[3]}
                  className={getSortClassNames(3)}
                >
                  Allocatable CPU
                </td>
                <td
                  onClick={handleSortCallbacks[4]}
                  className={getSortClassNames(4)}
                >
                  Idle CPU
                </td>
                <td
                  onClick={handleSortCallbacks[5]}
                  className={getSortClassNames(5)}
                >
                  Memory usage
                </td>
                <td
                  onClick={handleSortCallbacks[6]}
                  className={getSortClassNames(6)}
                >
                  Allocatable Memory
                </td>
                <td
                  onClick={handleSortCallbacks[7]}
                  className={getSortClassNames(7)}
                >
                  Unused Memory
                </td>
                <td
                  onClick={handleSortCallbacks[8]}
                  className={getSortClassNames(8)}
                >
                  Pods
                </td>
              </tr>
              {sortedNodes.map((node: NodeOverview, index) => (
                <tr key={node.nodeName}>
                  <td>{node.workerGroup}</td>
                  <td>
                    <button
                      onClick={handleNodeClickCallbacks[index]}
                      className={"underline"}
                    >
                      {node.nodeName}
                    </button>
                  </td>
                  <td>{Number(node.cpuUsage).toFixed(2)}</td>
                  <td>{Number(node.allocatableCpu).toFixed(2)}</td>
                  <td>{Number(node.idleCpu).toFixed(2)}</td>
                  <td>{adjustByteSize(Number(node.memoryUsage))}</td>
                  <td>{adjustByteSize(Number(node.allocatableMemory))}</td>
                  <td>{adjustByteSize(Number(node.unusedMemory))}</td>
                  <td>{node.pods}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default NodesOverview;
