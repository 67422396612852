import { Grid, Icon, Select } from "semantic-ui-react";
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import FetchAPI from "../../../api/FetchAPI";
import {
  convertArrayToSelectOptions,
  toastError,
} from "../../../app_shared_functions";
import MonitoringDashboardModal, {
  ModalData,
} from "../../modals/MonitoringDashboardModal.";
import {
  GetShootMonitoringCredentialsRequest,
  GetShootMonitoringCredentialsResponse,
} from "../Monitoring.types";
import { Dashboard } from "../Monitoring";
import NodeSelect, { Options as NodeNames } from "./NodeSelect";

type MonitoringHeaderProps = GetShootMonitoringCredentialsRequest & {
  dashboards: Dashboard[];
  selectedDashboard: Dashboard;
  changeSelectedDashboard: (e: SyntheticEvent, d: Object) => void;
  nodeNames: NodeNames;
  selectedNode: string;
  changeSelectedNode: (e: SyntheticEvent, d: Object) => void;
  displayNodeSelect?: boolean;
};

const MonitoringHeader = ({
  gardenerRegionTag,
  openStackRegionTag,
  projectId,
  shootName,
  dashboards,
  selectedDashboard,
  changeSelectedDashboard,
  nodeNames,
  selectedNode,
  changeSelectedNode,
  displayNodeSelect = false,
}: MonitoringHeaderProps) => {
  const initialModalData = useMemo(
    () => ({ header: "Dashboard", url: "", username: "", password: "" }),
    [],
  );

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalData>(initialModalData);
  const [loading, setLoading] = useState<boolean>(false);
  const [credentials, setCredentials] =
    useState<GetShootMonitoringCredentialsResponse>();

  const openPrometheusModal = useCallback(() => {
    if (!credentials) return;
    setModalData({ ...credentials.prometheus, header: "Prometheus Dashboard" });
    setModalOpen(true);
  }, [credentials]);

  const openPlutonoModal = useCallback(() => {
    if (!credentials) return;
    setModalData({ ...credentials.plutono, header: "Plutono Dashboard" });
    setModalOpen(true);
  }, [credentials]);

  const closeModal = useCallback(() => {
    setModalData(initialModalData);
    setModalOpen(false);
  }, [initialModalData]);

  const fetchCredentials = () => {
    setLoading(true);
    FetchAPI.Gardener.Shoots.Monitoring.getShootMonitoringCredentials({
      gardenerRegionTag,
      openStackRegionTag,
      projectId,
      shootName,
    })
      .then((response) => {
        setCredentials(response.data);
      })
      .catch((e) => {
        toastError(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(fetchCredentials, [
    gardenerRegionTag,
    openStackRegionTag,
    projectId,
    shootName,
  ]);

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column
            largeScreen={10}
            computer={16}
            tablet={10}
            widescreen={9}
            className={
              "flex justify-content-start align-items-center padding-left-30 padding-top padding-bottom"
            }
          >
            <span className={"margin-right-10 font-S"}>Dashboard: </span>
            <Select
              options={convertArrayToSelectOptions(dashboards)}
              value={selectedDashboard}
              onChange={changeSelectedDashboard}
              className={"select-box full max-width-250"}
            />
            {selectedDashboard === dashboards[1] && displayNodeSelect && (
              <>
                <span className={"margin-left-30 margin-right-10 font-S"}>
                  Node:{" "}
                </span>
                <NodeSelect
                  selected={selectedNode}
                  options={nodeNames}
                  onChange={changeSelectedNode}
                />
              </>
            )}
          </Grid.Column>
          <Grid.Column
            largeScreen={6}
            tablet={16}
            widescreen={7}
            className="dashboard-buttons flex padding-right-30 padding-top padding-bottom justify-content-end align-items-center"
          >
            {loading ? (
              <Icon name="spinner" loading />
            ) : (
              <>
                <button
                  className="button button--orange button--small margin-right-10"
                  onClick={openPlutonoModal}
                >
                  Open Plutono Dashboard (Grafana)
                </button>
                <button
                  className="button button--orange button--small"
                  onClick={openPrometheusModal}
                >
                  Open Prometheus Dashboard
                </button>
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {modalOpen && (
        <MonitoringDashboardModal onClose={closeModal} modalData={modalData} />
      )}
    </>
  );
};

export default MonitoringHeader;
