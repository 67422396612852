type PluginManifest = {
  id: string;
  events: string[];
  afterLayout: Function;
};

/**
 * This plugin makes sure y axis is adjusted to contain all dataset values
 * and no lines in the chart are over top limit. It also stops dynamic adjustment
 * of y axis values.
 */
const Scale = (): PluginManifest => {
  let yMax: number;

  const afterLayout = (chart: any): void => {
    const yAxis = chart.scales["y-axis-0"];

    if (yAxis && !yMax) {
      yMax = yAxis.max;

      const topDatasetValue = chart.data.datasets.reduce(
        (datasetMax: number, dataset: any) => {
          const top = dataset.data.reduce(
            (top: number, value: number) => (value > top ? value : top),
            0,
          );
          return top > datasetMax ? top : datasetMax;
        },
        0,
      );

      if (0.7 * yMax > topDatasetValue) {
        yAxis.options.ticks.max = yMax;
      } else {
        yAxis.options.ticks.max = topDatasetValue * 1.3;
      }

      chart.update();
    }
  };

  return {
    id: "yScaleUpdate",
    events: [],
    afterLayout,
  };
};

export default Scale;
