import { Grid } from "semantic-ui-react";
import React, { useCallback, useMemo } from "react";
import { Sample, WorkerGroupDetails } from "../Monitoring.types";
import Line from "./PrometheusChart/Line";
import { keysOf } from "../../../shared-functions/objects";
import { Colors } from "./PrometheusChart/Prometheus.types";
import { NoStringsOrNumbers } from "../../types";

type WorkerPoolOverviewDashboardProps = {
  workerGroupsDetails: WorkerGroupDetails[];
};

type WorkerGroupSampleFields = Pick<
  WorkerGroupDetails,
  NoStringsOrNumbers<WorkerGroupDetails>
>;

const WorkerGroupsDetails = ({
  workerGroupsDetails,
}: WorkerPoolOverviewDashboardProps) => {
  const samples = useMemo(
    () =>
      workerGroupsDetails.reduce(
        (currentSamples: Sample[], workerGroup): Sample[] => {
          const samples = Object.keys(workerGroup).reduce(
            (currentSamples: Sample[], key) => {
              if (
                typeof workerGroup[key as keyof WorkerGroupDetails] === "string"
              ) {
                return currentSamples;
              }
              const samples = workerGroup[key as keyof WorkerGroupSampleFields];
              return samples.length > currentSamples.length
                ? samples
                : currentSamples;
            },
            [],
          );
          return samples.length > currentSamples.length
            ? samples
            : currentSamples;
        },
        [],
      ),
    [workerGroupsDetails],
  );

  const labels = samples.map((sample) =>
    new Date(sample.time.date).toLocaleTimeString([], { timeStyle: "short" }),
  );

  const createDataset = useCallback(
    (workerName: string, index: number, data: Sample[]) => {
      const lengthDiff = samples.length - data.length;
      if (lengthDiff > 0) {
        for (let i = 0; i < lengthDiff; i++) {
          data.unshift({ ...data[i], value: null });
        }
      }
      return {
        label: workerName,
        borderColor: Colors[keysOf(Colors)[index]],
        data: data,
      };
    },
    [samples.length],
  );

  const cpuUsageDatasets = workerGroupsDetails.map((worker, index) =>
    createDataset(worker.workerName, index, worker.cpuUsage),
  );
  const memoryUsageDatasets = workerGroupsDetails.map((worker, index) =>
    createDataset(worker.workerName, index, worker.memoryUsage),
  );
  const cpuUtilizationDatasets = workerGroupsDetails.map((worker, index) =>
    createDataset(worker.workerName, index, worker.cpuUtilization),
  );
  const memoryUtilizationDatasets = workerGroupsDetails.map((worker, index) =>
    createDataset(worker.workerName, index, worker.memoryUtilization),
  );
  const numberOfNodesDatasets = workerGroupsDetails.map((worker, index) =>
    createDataset(worker.workerName, index, worker.numberOfNodes),
  );
  const numberOfPodsDatasets = workerGroupsDetails.map((worker, index) =>
    createDataset(worker.workerName, index, worker.numberOfPods),
  );

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8} className={"margin-bottom-40"}>
          <Line
            title={"Average CPU Usage of Nodes in Worker Group"}
            labels={labels}
            tooltipLabels={labels}
            verticalLabel={"Cores"}
            verticalUnit={""}
            verticalPrecision={2}
            tooltipUnit={" core"}
            tooltipPrecision={2}
            datasets={cpuUsageDatasets}
          />
        </Grid.Column>
        <Grid.Column width={8} className={"margin-bottom-40"}>
          <Line
            title={"Average Memory Usage in Worker Group"}
            labels={labels}
            tooltipLabels={labels}
            verticalLabel={"Memory"}
            verticalUnit={" GB"}
            verticalPrecision={2}
            tooltipUnit={" GB"}
            tooltipPrecision={2}
            datasets={memoryUsageDatasets}
          />
        </Grid.Column>
        <Grid.Column width={8} className={"margin-bottom-40"}>
          <Line
            title={"Average CPU Utilization of Nodes in Worker Group"}
            labels={labels}
            tooltipLabels={labels}
            verticalLabel={"CPU (%)"}
            verticalUnit={""}
            verticalPrecision={2}
            tooltipUnit={"%"}
            tooltipPrecision={2}
            datasets={cpuUtilizationDatasets}
          />
        </Grid.Column>
        <Grid.Column width={8} className={"margin-bottom-40"}>
          <Line
            title={"Average Memory Utilization of Nodes in Worker Group"}
            labels={labels}
            tooltipLabels={labels}
            verticalLabel={"Memory (%)"}
            verticalUnit={""}
            verticalPrecision={2}
            tooltipUnit={"%"}
            tooltipPrecision={2}
            datasets={memoryUtilizationDatasets}
          />
        </Grid.Column>
        <Grid.Column width={8} className={"margin-bottom-40"}>
          <Line
            title={"Number of Nodes in Worker Group"}
            labels={labels}
            tooltipLabels={labels}
            verticalLabel={"Nodes"}
            verticalUnit={""}
            verticalPrecision={2}
            tooltipUnit={""}
            tooltipPrecision={2}
            datasets={numberOfNodesDatasets}
          />
        </Grid.Column>
        <Grid.Column width={8} className={"margin-bottom-40"}>
          <Line
            title={"Average # of Pods on each Node in Worker Group"}
            labels={labels}
            tooltipLabels={labels}
            verticalLabel={"Pods"}
            verticalUnit={""}
            verticalPrecision={2}
            tooltipUnit={""}
            tooltipPrecision={2}
            datasets={numberOfPodsDatasets}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default WorkerGroupsDetails;
