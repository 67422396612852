import {
  GetShootMonitoringCredentialsRequest,
  GetShootMonitoringCredentialsResponse,
  GetShootNodeDetailsRequest,
  GetShootNodeDetailsResponse,
  GetShootNodeNamesRequest,
  GetShootNodeNamesResponse,
  GetShootWorkerGroupDetailsRequest,
  GetShootWorkerGroupDetailsResponse,
  GetShootWorkerGroupNodesOverviewRequest,
  GetShootWorkerGroupNodesOverviewResponse,
} from "../../../gardener/detailed-view/Monitoring.types";
import getFetch from "../../getFetch";
import config from "../../../config";
import { AxiosResponse } from "axios";

const Monitoring = {
  getShootMonitoringCredentials: function (
    request: GetShootMonitoringCredentialsRequest,
  ): Promise<AxiosResponse<GetShootMonitoringCredentialsResponse>> {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${request.gardenerRegionTag}/shoot/${request.openStackRegionTag}/${request.projectId}/${request.shootName}/monitoring`,
      type: "get",
    });
  },
  getShootWorkerGroupDetails: function (
    request: GetShootWorkerGroupDetailsRequest,
  ): Promise<AxiosResponse<GetShootWorkerGroupDetailsResponse>> {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${request.gardenerRegionTag}/shoot/${request.openStackRegionTag}/${request.projectId}/${request.shootName}/monitoring/workers/${request.workerName}`,
      type: "get",
    });
  },
  getShootWorkerGroupNodesOverview: function (
    request: GetShootWorkerGroupNodesOverviewRequest,
  ): Promise<AxiosResponse<GetShootWorkerGroupNodesOverviewResponse>> {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${request.gardenerRegionTag}/shoot/${request.openStackRegionTag}/${request.projectId}/${request.shootName}/monitoring/workers/${request.workerName}/nodes/overview`,
      type: "get",
    });
  },
  getShootNodeDetails: function (
    request: GetShootNodeDetailsRequest,
  ): Promise<AxiosResponse<GetShootNodeDetailsResponse>> {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${request.gardenerRegionTag}/shoot/${request.openStackRegionTag}/${request.projectId}/${request.shootName}/monitoring/nodes/${request.nodeName}`,
      type: "get",
    });
  },
  getShootNodeNames: function (
    request: GetShootNodeNamesRequest,
  ): Promise<AxiosResponse<GetShootNodeNamesResponse>> {
    return getFetch({
      url: `${config.api_url}/gardener/v1/${request.gardenerRegionTag}/shoot/${request.openStackRegionTag}/${request.projectId}/${request.shootName}/monitoring/workers/${request.workerName}/nodes`,
      type: "get",
    });
  },
};

export default Monitoring;
